@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;500;600;700;800&display=swap");
* {
  color: #333333;
  font-family: "Overpass", sans-serif;
}
.para {
  font-size: 1rem;
}

/* Header */
.top-nav {
  justify-content: center;
  align-items: center;
  margin: 15px auto;
}
.register {
  gap: 10px;
}
.butn {
  background-color: #00aeef;
  padding: 6px 25px 6px 25px;
  color: #fff;
  border-radius: 25px;
  border: none;
  /* margin: 1rem; */
}

.hero-line-2 {
  text-align: center;
  align-items: center;
}
.hero-line-2-icon {
  padding: 5px 8px;
  border: 2px solid #005bab;
  border-radius: 100px;
  margin: 10px;
}
.hero-line-2-icon path {
  border: 2px solid #005bab;
  color: #005bab;
}
.hero-line-2-text {
  font-size: 1rem;
  font-weight: 500;
  color: #005bab;
}
.book {
  border-radius: 50px;
}
.hero-sec {
  gap: 50px;
}
.span {
  color: #00aeef;
  margin: 0 10px 0 10px;
}
.navbar {
  background-color: #00aeef;
}
.navbar-lists {
  gap: 2rem;
  font-size: 1rem;
}
.nav-list {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
}
.cart-icon path {
  fill: #fff;
}

/* Section two */
.secTwo {
  background-color: #005bab;
  padding: 3rem 0 12rem 0;
}
.tstOne {
  width: 250px;
  text-align: center;
  font-size: 1rem;
}
.tst-title {
  color: #fff;
  font-size: 1.5rem;
}

.tst-icon {
  background-color: #fff;
  border-radius: 100px;
  padding: 25px;
  margin: 25px;
}

/* Section three */
.secThree {
  background-color: rgb(236, 236, 236);
  margin-top: -8rem;
  gap: 50px;
  padding: 0;
  font-size: 1.5rem;
}
.secThree-info {
  margin: auto 0;
  flex-direction: column;
}
.sti {
  background-color: antiquewhite;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  border-bottom: 1px solid red;
}
.secThree-info p {
  margin: 0;
  color: #005bab;
}
.secThree-icons {
  fill: #005bab;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
